import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Button from './Button';
import CountSignatures from './CountSignatures';

import styles from './Hero.module.scss';

const Hero = ({background, channel}) => (
  <div className={styles.container}>
    <Img
      className={styles.image}
      fluid={background}
      style={{position: false}}
    />
    <div className={styles.overlay}>
      <div className={styles.credits}>Foto: Marino Mondek</div>

      <div className="wrap">
        <h1 className={styles.title}>
          <span>Passe livre para estudantes</span>
          de cursinhos populares:
          <strong>Sanciona, Bruno Covas!</strong>
        </h1>
        <p className={styles.description}>
          Convocamos todos os estudantes e professores dos cursinhos populares e a comunidade no geral a se somarem à luta pelo passe livre para estudantes de cursinhos populares e dizer: SANCIONA, Bruno Covas!
        </p>
        <CountSignatures channel={channel} />
        <p className={styles.description}>
          <Button to="#sign">Assine agora</Button>
        </p>
      </div>
    </div>
  </div>
);

Hero.propTypes = {
  background: PropTypes.object,
  channel: PropTypes.string,
};

export default Hero;
