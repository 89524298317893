import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import ScrollableAnchor from 'react-scrollable-anchor';

import FormSignature from '../components/FormSignature';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import SEO from '../components/SEO';

export default function render({data}) {
  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} />

      <Hero
        background={data.hero.childImageSharp.fluid}
        channel={data.site.siteMetadata.channel}
      />

      <main className="main">
        <div className="wrap">
          <p>Os estudantes de baixa renda conquistaram uma vitória importante: a Câmara Municipal de SP aprovou o projeto de Lei 508/2016, que prevê a gratuidade nas passagens do transporte público para alunos de cursinhos populares.</p>

          <p>Os cursinhos populares além de serem uma oportunidade de estudo, se tornam pontos de referência para a juventude nos bairros mais afastados. Alguns desses jovens ingressam na universidade, e então encorajam e inspiram todo seu entorno. Esse incentivo no transporte pode significar a diferença entre o estudante continuar no cursinho até o vestibular ou abandonar após o primeiro mês.</p>

          <p>Ainda falta o prefeito sancionar o projeto de lei. Por isso convocamos todos os estudantes e professores dos cursinhos populares e a comunidade no geral a se somarem a essa luta e dizer: SANCIONA, Bruno Covas!</p>

          <p>#SancionaCovas<br />
          #Sanciona508<br />
          #CursinhosPopulares</p>
        </div>
      </main>

      <ScrollableAnchor id="sign">
        <FormSignature channel={data.site.siteMetadata.channel} />
      </ScrollableAnchor>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        channel
      }
    }

    hero: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
