import axios from "axios";

const api = axios.create({
  baseURL: "https://api.evag.io/",
  withCredentials: true
});

const locations = (country = null, state = null) => {
  let uri = "/locations";
  if (country) {
    uri += `/${country}`;
    if (state) {
      uri += `/${state}`;
    }
  }
  return api.get(uri);
};

const count = channel => {
  return api.get(`/channels/${channel}/count`);
};

const submit = (channel, values) => {
  const common = {};
  if ("evag" in window) {
    if ("getEvlt" in window.evag) {
      common.evlt = window.evag.getEvlt();
    }

    if ("getProjectID" in window.evag) {
      common.projectID = window.evag.getProjectID();
    }
  }

  const data = Object.assign({}, common, values);
  delete data.redirect;

  return api.post(`/channels/${channel}/submit`, data);
};

export { api, count, locations, submit };
