import React from 'react';
import PropTypes from 'prop-types';

import ExtLink from './ExtLink';

import Facebook from '../svg/facebook.svg';
import Twitter from '../svg/twitter.svg';
import WhatsApp from '../svg/whatsapp.svg';

import styles from './ShareButtons.module.scss';

const ShareButtons = ({title, url}) => {
  const u = encodeURIComponent(url);
  const t = encodeURIComponent(title);

  return (
    <div className={styles.container}>
      <ExtLink to={`https://facebook.com/sharer/sharer.php?u=${u}`}><Facebook /></ExtLink>
      <ExtLink to={`https://twitter.com/share?text=${t}&url=${u}`}><Twitter /></ExtLink>
      <ExtLink to={`https://api.whatsapp.com/send?text=${t}%0A${u}`}><WhatsApp /></ExtLink>
    </div>
  );
};

ShareButtons.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

export default ShareButtons;
